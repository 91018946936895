<template>
  <div id="dialog-permission" v-if="showContractProcessPermission">
    <div id="dialog-permission-header" >
      <p id="dialog-permission-title">{{title?title:'权限设置'}}</p>
      <div id="dialog-permission-close" @click="showContractProcessPermission = false">
        <svg-icon name="close" width="100%" height="100%"></svg-icon>
      </div>
    </div>
    <div id="permissionToolWrap">
      <organization-user-selection
          ref="contract_visible_organization-user-selection"
          :show_selected="true"
          dom_selector="#permissionToolWrap"
          :selected_users="permissionAccessUidList"
          :selected_organizations="permissionAccessOrganizationIdList"
          :show_permission="false"
          :organization_permission="organization_permission"
          :user_permission="user_permission"
          :manager_delete_disable="contract?true:false"
      >
      </organization-user-selection>
    </div>
    <div class="permissionPrompt">
      <div class="permissionPrompt-cancel permissionPrompt-item" @click="showContractProcessPermission = false">取消</div>
      <div class="permissionPrompt-confirm permissionPrompt-item" @click="confirmPermissionChange">确认</div>
    </div>
  </div>
</template>

<script>
import organizationUserSelection from "../../components/organizationUserSelection";

const RESOURCE_TARGET_TYPE = {
  USER: 'user',
  ORGANIZATION: 'organization',
};

import {getContractApprovalProcessAndPermission, saveOrUpdateContractPermissionAccess} from "../../api/api";
import {colorLog, MetaMessage} from "../../util";
import {globalTagClick} from "../../assets/js/tag/tag";

export default {
  name: "ContractPermissionWindow",
  props:['contract','approveMembers','initialData','show_permission','confirmCallBack','title'],
  components:{organizationUserSelection},
  watch:{
    'approveMembers':{
      deep:true,
      handler:function (){
        this.permissionAccessUidList=[];
        this.permissionAccessOrganizationIdList=[];
        if(this.approveMembers) {
          this.approveMembers.forEach((item) => {
            if (item.managerId) {
              if (item.managerType == 0) {
                this.permissionAccessUidList.push(item.managerId);
              } else {
                this.permissionAccessOrganizationIdList.push(item.managerId);
              }
            }
            else if(item.approver){
              if (item.approverType == 0) {
                this.permissionAccessUidList.push(item.approver);
              } else {
                this.permissionAccessOrganizationIdList.push(item.approver);
              }
            }
          })
        }
      }
    },
    'initialData':{
      deep:true,
      handler(){
        this.permissionAccessUidList = this.initialData.permissionAccessUidList;
        this.permissionAccessOrganizationIdList = this.initialData.permissionAccessOrganizationIdList;
        this.organization_permission = this.initialData.organization_permission;
        this.user_permission = this.initialData.user_permission;
      }
    }

  },
  data(){
    return{
      metaMessage: new MetaMessage(),
      //审核对话框相关的数据
      showContractProcessPermission:false,
      showPermission:this.show_permission==null?true:this.show_permission,
      permissionAccessUidList: [],
      permissionAccessOrganizationIdList: [],
      organization_permission:[],
      user_permission: {},

    }
  },
  methods:{
    isNotNull: function (obj) {
      return obj !== null && obj !== undefined && (obj+"").length > 0;
    },
    initContractSetting: function (callback) {
      getContractApprovalProcessAndPermission(this.contract.id).then(res => {
        console.log('getContractApprovalProcessAndPermission  res:', res);
        if (this.isNotNull(res.data.data)) {
          const contractSetting = res.data.data;
          return contractSetting;
        }
      }).then((contractSetting)=>{

        if (contractSetting != null) {
          // 初始化权限
          this.permissionAccessUidList = contractSetting.entityAccessibilityDTO.uids;
          this.permissionAccessOrganizationIdList = contractSetting.entityAccessibilityDTO.orgIds;
          this.organization_permission = {};
          this.user_permission = {};
          callback && callback();
        }

      }).catch(err => {
        console.log('getContractApprovalProcessAndPermission  err:', err);
        this.metaMessage['error']('初始化失败');
      })
    },
    openLoading: function() {
      this.$emit("openLoading");
    },
    closeLoading: function () {
      this.$emit("closeLoading");
    },
    openWindow(){
      console.log(this.approveMembers)
      if(this.contract) {
        this.initContractSetting(() => {
          this.showContractProcessPermission = true;
        });
      }
      else if(this.approveMembers){
        this.showContractProcessPermission = true;
      }else if(this.initialData){
        //templatePublish用到的逻辑
        this.showContractProcessPermission = true;
      }
    },
    /**
     * 从ContractProcessAndPermission页面粘的方法,进行了一些适应修改
     */
    saveContractApprovalProcessAndPermission: function () {
      const visibleObjects = this.$refs['contract_visible_organization-user-selection'].getSelectedObjects();
      this.openLoading();
      const entityAccessibilityDTO = {
        entityId:this.contract.id,
        entityType:1,
        uids:visibleObjects.filter((item)=>item.type===RESOURCE_TARGET_TYPE.USER).map((item)=>{
          return item.objectId
        }),
        orgIds:visibleObjects.filter((item)=>item.type===RESOURCE_TARGET_TYPE.ORGANIZATION).map((item)=>{
          return item.objectId
        })
      }

      saveOrUpdateContractPermissionAccess(this.contract.id, entityAccessibilityDTO).then(res => {
        console.log('saveOrUpdateContractPermissionAccess  res === ', res);
        this.metaMessage['info']('设置成功');
        this.closeLoading();
        this.showContractProcessPermission = false;
      }).catch(err => {
        console.error('saveOrUpdateContractPermissionAccess  err:', err);
        this.metaMessage['error']('设置权限失败');
        this.closeLoading();
      })
    },
    /**
     * 用户更改访问权限后点击确认
     */
    confirmPermissionChange(){
      globalTagClick('contract-permission-change');
      if(this.contract) {
        this.saveContractApprovalProcessAndPermission();
      }
      else if(this.approveMembers){
        console.log(this.$refs['contract_visible_organization-user-selection'].getSelectedObjects())
        let selectedObjects=this.$refs['contract_visible_organization-user-selection'].getSelectedObjects();
        this.confirmCallBack(selectedObjects);
        this.showContractProcessPermission = false;
      }else if(this.initialData){
        console.log('confirmPermissionChange this.initialData === ', this.initialData);
        colorLog.orange(this.$refs['contract_visible_organization-user-selection'].getSelectedObjects(),this.$refs['contract_visible_organization-user-selection'])
        let selectedObjects=this.$refs['contract_visible_organization-user-selection'].getSelectedObjects();
        this.confirmCallBack(selectedObjects);
        this.showContractProcessPermission = false;
      }
    },
  }
}
</script>

<style scoped>
/* permission样式 */

#permissionToolWrap{
  height: 50vh;
  min-height: 500px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
#dialog-permission{
  margin-bottom: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 700px;
  box-sizing: border-box;
  padding: 15px;
  z-index: calc(var(--contractViewMaskZIndex) + 1);
  box-shadow: 0 0 5px 2px #dfdede;
  background: #fff;
}


#dialog-permission-header{
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
  --permissionHeaderHeight:18px;
  height:var(--permissionHeaderHeight);
  box-sizing: content-box;
}
#dialog-permission-title{
  font-size: 16px;
  text-align: left;
  margin: 0;
  line-height:var(--permissionHeaderHeight);
  height:var(--permissionHeaderHeight);
}
#dialog-permission-close{
  color: #b7b1b1;
  transition: .2s color;
  height: var(--permissionHeaderHeight);
  width: var(--permissionHeaderHeight);
  cursor: pointer;
}
#dialog-permission-close:hover{
  color:var(--lightBlue);
}
.permissionPrompt{
  text-align: right;
  margin-top: 15px;
}
.permissionPrompt-item{
  display: inline-block;
  width: 80px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}
.permissionPrompt-confirm{
  background: var(--lightBlue);
  color:#fff;
  box-sizing: content-box;
  border: 1px solid var(--lightBlue);
}
.permissionPrompt-confirm:hover{
  background: var(--darkBlue)
}
.permissionPrompt-cancel{
  box-sizing: content-box;
  border: 1px solid #ccc;
  margin-right: 15px;
}
.permissionPrompt-cancel:hover{
  border-color: var(--lightBlue);
  color: var(--lightBlue);
  background-color:#ecf5ff;
}
</style>
