//java enum 转 js obj
//([A-Z|_]+)\((.+)\) -> $1:$2

export const BehaviorPermission = {
    NONE:"none",
//    MANAGER_ACTION:"managerAction",//管理员权限。
    TEMPLATE_APPROVAL:"templateApproval",// 模板审批
    SET_CONTRACT_VISIBILITY:"setContractVisibility",//设置合同可见性

    MANAGE_TEAM_APPROVAL_FLOW:"manageTeamContractApprovalFlow",//团队审批流管理。
    MANAGE_TAG:"manageTag",//标签管理
    MANAGE_PUBLISHED_TEMPLATE_CATEGORY:"managePublishedTemplateCategory", //模板类别管理
    MANAGE_DATA_DICTIONARY:"manageDataDictionary",//数据字典管理
    MANAGE_ORGANIZATION:"organizationManage",//组织结构管理
    MANAGE_TEAM_CONFIG:"manageTeamConfig",//团队配置信息管理。比如设置"设为定稿不用提交新版本"配置

    DRAFT_CONTRACT:"draftContract",//起草合同
    UPLOAD_CONTRACT_NEW_VERSION:"uploadContractNewVersion",// 上传合同新版本
    SAVE_AS_CONTRACT_NEW_VERSION:"saveAsContractNewVersion",// 保存为合同新版本
    SET_CONTRACT_APPROVAL_STATUS_BY_FORCE:"setContractApprovalStatusByForce",//强行设置合同的状态。
    REVERT_CONTRACT_TO_DRAFT_STATUS:"revertContractToDraftStatus",//将合同退回草稿状态


    EDIT_CONTRACT_CONTENT:"editContractContent",// 编辑合同内容
    UPDATE_CONTRACT_META_INFO:"updateContractMetaInfo",//更新合同基础数据（比如标题，概览）
    CANCEL_CONTRACT:"cancelContract",// 取消合同
    DELETE_CONTRACT:"deleteContract",// 删除合同
    EXPORT:"export",// 导出

    UPLOAD_ATTACHMENT:"uploadAttachment",//上传附件
    DOWNLOAD_ATTACHMENT:"downloadAttachment",//下载附件
    DELETE_ATTACHMENT:"deleteAttachment",//删除附件
    VIEW_ATTACHMENT:"viewAttachment",//查看附件

    VIEW_COMMENT:"viewComment",//查看评论
    ADD_COMMENT:"addComment",//添加评论
    SOLVE_COMMENT:"solveComment",//解决自己的评论
    DELETE_COMMENT:"deleteComment",//删除自己的评论

    VERSION_DIFF:"versionDiff",//版本diff
    VERSION_ROLLBACK:"versionRollback",//版本回退

    // 接口需要二合一
    UPLOAD_SIGNED_CONTRACT:"uploadSignedContract",//上传签署版本合同
    LAUNCH_COOPERATION:"launchCooperation",//发起协作
};


export const checkUserBehaviorPermission = (teamId,shortKey)=>{

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if(!userInfo)return false;
    if(teamId!=userInfo.mainSiteTeamId)return false;
    if(userInfo.permissions.find((item)=>item==shortKey)){
        return true;
    }
    return false;
}

export const existBehaviorPermission = (permissions,shortKey)=>{
    if(permissions && permissions.find((item)=>item==shortKey)){
        return true;
    }
    return false;
}
